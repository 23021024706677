<template>
  <div class="teacher-list">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="!$route.query.teacher">
        风采展示
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="$route.query.teacher">
        讲师列表
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="list">
      <div style="width: 1200px; margin: auto;display: flex;padding-left: 78px;" v-if="has_type == 1">
        <div class="type-title" @click="changeList" >
          <span  data-tid="1" :class="selected === 'button1' ? 'active' : ''" @click="select('button1')">企业</span>
        </div>
        <div class="type-title" @click="changeList">
          <span  data-tid="2" :class="selected === 'button2' ? 'active' : ''" @click="select('button2')">产品</span>
        </div>
        <div class="type-title" @click="changeList">
          <span  data-tid="3" :class="selected === 'button3' ? 'active' : ''" @click="select('button3')">企业家</span>
        </div>
        <div class="type-title" @click="changeList">
          <span  data-tid="4" :class="selected === 'button4' ? 'active' : ''" @click="select('button4')">兰台人</span>
        </div>
      </div>
      <div
        class="item-teacher"
        v-for="teacher in teacherList.list"
        :key="teacher.id"
        @click="toDetail(teacher.id)"
      >
        <div class="item">
          <div class="img-box">
            <img :src="'https://tot.xlteacher.cn' + teacher.pic" />
          </div>
          <div class="p-box">
            <p class="name">{{ teacher.name }}</p>
            <!-- <p class="desc">{{ teacher.position }}</p> -->
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      :current-page="currentPage"
      layout="prev,pager,next"
      :total="teacherList.count"
      :page-size="10"
      background
      prev-text="上一页"
      next-text="下一页"
      @current-change="pageChange"
      hide-on-single-page
    >
    </el-pagination>
  </div>
</template>

<script>
import Teacher from "../components/Teacher.vue"
export default {
  data() {
    return {
      has_type:1,//是否传type_id  1显示  2隐藏
      teacherList: {},
      currentPage: 1,
      selected: 'button4', // 默认选中第一个按钮
    }
  },

  components: { Teacher },

  computed: {},
  mounted() {
    // if (this.$route.query.pid) {
    // }

    if (!this.$route.query.teacher) {
      this.getTeacherList()
    }
    if (this.$route.query.teacher) {
      this.getMoreList()
    }
    if (this.$route.query.style) {
      this.getMoreStyleList()
    }
  },
  methods: {
    select(button) {
      this.selected = button; // 更新选中的按钮
    },
    toDetail(id) {
      let type = ""
      if (this.$route.query.teacher) {
        type = "tid"
      } else {
        type = "pid"
      }
      this.$router.push(`/tdetail?${type}=${id}`)
    },
    changeList(e) {
      if (e.target.tagName !== "SPAN") return;
      this.barTitle = e.target.innerText;
      let tid = e.target.dataset.tid;
      this.tid = tid;
      this.getTeacherList(this.currentPage, this.tid);
      this.getMoreStyleList(this.currentPage, this.tid);
    },

    async getTeacherList(page = 1,tid = 4) {
      let { data } = await this.axios.get(
        `/index/teacher_list?page=${page}&page_size=10&type_id=${tid}`
      )
      this.teacherList = data
      this.currentPage = page
      this.has_type = 1
    },
    async getMoreList(page = 1) {
      let { data } = await this.axios.get(
        `/index/more_teacher?page=${page}&page_size=10`
      )
      this.teacherList = data
      this.currentPage = page
      this.has_type = 2
    },
    async getMoreStyleList(page = 1,tid = 1) {
      let { data } = await this.axios.get(
        `/index/more_style_show?page=${page}&page_size=10&type_id=${tid}`
      )
      this.teacherList = data
      this.currentPage = page
      this.has_type = 1
    },
    pageChange(page) {
      if (!this.$route.query.teacher) {
        this.getTeacherList(page)
      }
      if (this.$route.query.teacher) {
        this.getMoreList(page)
      }
      if (this.$route.query.style) {
        this.getMoreStyleList(page)
      }
    },
  },
  watch: {
    $route(oldVal, newVal) {
      if (!newVal.query.style) {
        this.getMoreStyleList()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-list {
  min-height: calc(100vh - 70px);
  background-color: #F8F8F8;
  padding-top: 14px;
  // padding-bottom: 90px;
  .list {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .type-title{
      width: 250px;
      height: 63px;
      background: #F1F2F3;
      border-radius: 4px;
      margin-left: 18px;
      margin-bottom: 35px;
      margin-top: 10px;
      span{
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        line-height: 63px;
        padding-left: 96px;
        display: block;
      }
      .active {
        background-color: #8CCDFD;
        color: #1E76E0 ;
      }
    }
    
    .item-teacher {
      width: 255px;
      height: 228px;
      background: #FFFFFF;
      border-radius: 4px;
      // width: calc(1200px / 5);
      margin: 15px 0;
      margin-left: 45px;

      box-sizing: border-box;
      .item {
        width: 255px;
        height: 159px;
        background: #CCCFD4;
        margin: 0 auto;
        cursor: pointer;
        user-select: none;
        box-sizing: border-box;
        .img-box {
          width: 255px;
          height: 159px;
          img {
            width: 255px; /* 或者你想要的固定宽度 */
            height: 159px; /* 高度自动，保持等比 */
            object-fit: scale-down; /* 图片整体缩小，不改变原有尺寸 */
          }
        }
        .p-box {
          // height: calc(100% - 280px);
          height: 71px;
          background: #FFFFFF;
          display: flex;
          flex-direction: column;
          padding: 5px;
          box-sizing: border-box;
          padding: 20px;
          p {
            margin: 0;
          }
          .desc {
            color: #949292;
            font-size: 13px;
            // margin: 12px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .name {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            text-align: center;
            white-space: pre-wrap; /* CSS3属性，支持换行、空格和自动换行 */
            word-wrap: break-word; 
          }
        }
      }
    }
  }
  .el-pagination {
    text-align: center;
    margin-top: 36px;
  }
}
</style>
<style lang="scss">
.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
</style>
